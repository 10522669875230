:root {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  :root {
    font-family: "Inter var", sans-serif;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.dashed-line {
  background-image: linear-gradient(
    to bottom,
    #23213e 0%,
    #23213e 50%,
    transparent 50%
  );
  background-size: 8px 9px;
  background-repeat: repeat-y;
}
